import styled from "styled-components";
import { StepState, Step as StepType } from "./types/Step";
import { Step } from "./components/Step";
import { StepsType } from "./types/StepsType";
import { Colors } from "../../styles/themeNew";

type Props = {
  type: StepsType;
  steps: Array<StepType>;

  onChange: (stepCode: string) => void;
};

export const Steps = (props: Props) => {
  return (
    <StyledContainer>
      <StyledTopContainerList>
        {props.steps.map((step, index) => (
          <StyledElement key={step.code} stepState={step.state}>
            <Step
              type={props.type}
              step={step}
              index={index + 1}
              onChange={() => props.onChange(step.code)}
            />
           
          </StyledElement>
        ))}
      </StyledTopContainerList>
    </StyledContainer>
  );
};

const StyledElement = styled.li<{ stepState: StepState }>`
  width: 200px;
  height: 50px;
  position: relative;
  background-color: ${(props) => getStepperColor(props.stepState)};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: -10px;
  clip-path: polygon(92.08% 0%, 99.956% 48%, 99.956% 52%,  92.08% 100%, 0% 100%, 7.5% 52%,  7.5% 48%, 0% 0%);

  &:before {
    content: ${(props) => getContentValue(props.stepState)};
    display: block;
    position: absolute;
    top: 2px;
    left: 3px;
    width: 195px;
    height: 46px;
    background-color: white;
    clip-path: inherit;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: white;
`;

const StyledTopContainerList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  gap: 0px;
  padding: 0;

  li {
    &:first-child {
      clip-path: polygon(92.08% 0%, 99.956% 48%, 99.956% 52%,  92.08% 100%, 0% 100%, 0% 0%);

    }

    &:last-child {
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 7.5% 52%,  7.5% 48%, 0% 0%);
    }
  }
`;

const getStepperColor = (stepState: StepState) => {
  if (stepState === StepState.DISABLED || stepState === StepState.WITH_SUCCESS) {
    return Colors.MONOCHROM[200];
  }

  if (stepState === StepState.SELECTED) {
    return Colors.GREEN[500];
  }

  return Colors.MONOCHROM[950];
};

const getContentValue = (stepState: StepState) => {
  if ((stepState === StepState.DISABLED) || (stepState === StepState.WITH_SUCCESS)) {
    return "close-quote";
  }

  return "none";
};
